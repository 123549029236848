
import { defineComponent, watch, onMounted } from 'vue';
import { Notify, Toast } from 'vant';

import { isLoggedIn, isRegisterToken, googleIdToken } from '@/composable/auth';
import router, { RouteRecord } from '@/router';

export default defineComponent({
  setup() {
    async function login() {
      window.google?.accounts.id.prompt((res) => {
        const message = `
            getNotDisplayedReason: ${res.getNotDisplayedReason() || ''}
            getDismissedReason: ${res.getDismissedReason() || ''}
            getNotDisplayedReason: ${res.getNotDisplayedReason() || ''}
            getSkippedReason: ${res.getSkippedReason() || ''}
            getMomentType: ${res.getMomentType() || ''}
          `;
        // Notify({
        //   type: 'primary',
        //   message,
        //   duration: 5000,
        // });
        console.log(message);
      });
    }

    function renderButton() {
      try {
        window.google?.accounts.id.renderButton(document.getElementById('js-button') as HTMLElement, {
          width: '1000',
          type: 'standard',
          theme: 'filled_blue',
          size: 'large',
          text: 'continue_with',
          shape: 'rectangular',
          logo_alignment: 'left',
          locale: 'zh_TW',
        });
      } catch (err) {
        let message = 'unknown error';
        if (err instanceof Error) message = err.message;
        Notify({ type: 'danger', message });
      }
    }

    watch(googleIdToken, (val) => {
      if (val) {
        Toast.loading({
          message: '登入中...',
          duration: 0,
        });

        router.push({ name: RouteRecord.Home.name }).finally(() => {
          Toast.clear();
        });
      }
    });

    onMounted(() => {
      login();
      renderButton();
    });

    return {
      login,
    };
  },
  beforeRouteEnter(to, from, next) {
    if (isLoggedIn.value) {
      return next({ name: RouteRecord.Home.name });
    } else if (isRegisterToken.value) {
      return next({ name: RouteRecord.SignUp.name });
    }

    next();
  },
});
